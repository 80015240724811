// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authBaseUrl: 'https://paygateway-back.dev.cividas.com',
  adminBaseUrl: 'https://paygateway-back.dev.cividas.com',
  isDebugMode: false,
  idle: 1,
  idleTimeout: 900,
  idlePingInterval: 15,
  intervalRefreshPayments: 60000,
  paginationValues: [5, 10, 15],
  paymentCheckoutScript: 'https://cdn.paymentez.com/ccapi/sdk/payment_checkout_3.0.0.min.js',
  paymentezApiUrl: 'https://ccapi-stg.paymentez.com/v2/transaction/init_reference/',
  envMode: 'stg',
  transaction: 'transaction',
  refund: 'refund'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
